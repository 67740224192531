<template>
	<div class="popup-cookies">
		<div class="content-cookies">
			<div class="desc-cookies">
				<div class="title">Acest website folosește cookies</div>
				<div class="desc">Folosim cookie-uri pentru analiză, pentru marketing și pentru a salva preferințele utilizatorilor astfel încât să oferim o experiență frumoasă. Acestea sunt necesare pentru ca website-ul să funcționeze corect și să îl putem îmbunătății constant.</div>
			</div>
			<div class="actions-cookies">
				<button class="accept" @click="closePopup('accept')">Desigur, sunt de acord</button>
				<button class="decline" @click="closePopup('decline')">Nu, eu nu vreau cookies</button>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				
			};
		},
		mounted() {
			
		},
		methods: {
			closePopup(type){
				this.$emit("response_cookie", type);
			},
		}
	};
</script>